import React, { useState, useEffect, Fragment, useContext } from 'react';
import { KeyboardDatePicker,MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Loading from './Loading';
import {format} from 'date-fns';
import MONTHS from '../consts/MONTHS';
import { fetchStartDates } from '../services/AssessmentService';
import { AssessmentContext } from '../context/AssessmentContext';


const ResultsStartDate = ({ workPermits }) => {
  const [assessmentState] = useContext(AssessmentContext);
  const [acceptanceDate, setAcceptanceDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [latestAcceptanceDate, setLatestAcceptanceDate] = useState(null);
  const [startDates, setStartDates] = useState([]);

  useEffect(() => {
    if (workPermits.length > 0) {
      getStartDates(format(new Date(), 'yyyy-MM-dd'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Disable the selection of Saturday and Sunday
   * @param {object} date The date provided by the KeyboardDatePicker component
   */
  const disableWeekends = date => {
    return date.getDay() === 0 || date.getDay() === 6;
  }

  const getStartDates = async (acceptanceDate) => {
    setIsLoading(true);

    // use premium time if selected
    const startDateObject = {
      acceptanceDate: acceptanceDate, // TODO FIX TYPO IN BACKEND
      totalProcessingTime: assessmentState.onlyUsePremiumTimes ? workPermits[0].totalPremiumProcessingTime : workPermits[0].totalProcessingTime  // TODO FIX TYPO IN BACKEND
    };

    const startDatesResponse = await fetchStartDates(workPermits[0].workPermitId, startDateObject);

    if (startDatesResponse) {
      setAcceptanceDate(acceptanceDate);
      setLatestAcceptanceDate(startDatesResponse.latestAcceptenceDate); // TODO FIX TYPO IN BACKEND
      setStartDates(startDatesResponse.startDates);
    }
    
    setIsLoading(false);
  };

  const returnMonth = month => {
    return MONTHS[month];
  }
  
  let newFormatDate =acceptanceDate && acceptanceDate.split('-').reverse().join('-')
  // console.log(newFormatDate)
  return (
    workPermits.length > 0 &&
    <Fragment>
      <div className="row my-5">
        <div className="col-12">
          <h6 className="gw__results-permit-heading m-0">
            <i className="icon-calender_special gw__results-icon" />
            Indicative Start Dates
          </h6>
        </div>

        {
          startDates.length > 1
          ?
          <Fragment>
            <div className="d-flex mt-2 mt-md-4">
              <div className="gw__results-start-date-wrapper">
                {
                  isLoading
                  ? <Loading />
                  : <div className="gw__results-start-date-container">
                      <div className="gw__results-start-date-box">
                        <div className="gw__results-start-date-recommended">Suggested</div>
                        <div className="gw__results-start-date-month" aria-describedby="start-dates-info" aria-labelledby="start-dates">
                          {returnMonth(new Date(startDates[0].date).getMonth())}
                        </div>
                        <div className="gw__results-start-date-day" aria-describedby="start-dates-info" aria-labelledby="start-dates recommended">
                          {new Date(startDates[0].date).getDate()}
                        </div>
                        <div className="gw__results-start-date-box__corner" />
                      </div>
                      <div className="gw__results-start-date-box">
                        <div className="gw__results-start-date-month" aria-describedby="start-dates-info" aria-labelledby="start-dates">
                          {returnMonth(new Date(startDates[1].date).getMonth())}
                        </div>
                        <div className="gw__results-start-date-day" aria-describedby="start-dates-info" aria-labelledby="start-dates recommended">
                          {new Date(startDates[1].date).getDate()}
                        </div>
                        <div className="gw__results-start-date-box__corner" />
                      </div>
                      <div className="gw__results-start-date__arrow" />
                    </div>
                }
              </div>
            </div>

            <div className="col-12 col-md mt-md-4">
              <p className="font-weight-light">
                These indicative start dates are based on immigration processing timelines only. 
                They assume that the immigration application process starts on <strong>{newFormatDate && newFormatDate.split('T')[0]}</strong>.
              </p>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className="gw__results-date-picker"
                  format="yyyy-MM-dd"
                  keyboardIcon={<span className="btn btn-primary">CHANGE IMMIGRATION INITIATION DATE</span>}
                  minDate={format(new Date(), 'yyyy-MM-dd')}
                  maxDate={latestAcceptanceDate && latestAcceptanceDate.split('T')[0]}
                  onChange={date => getStartDates(format(date,'yyyy-MM-dd'), workPermits[0].totalProcessingTime, workPermits[0].workPermitId)}
                  shouldDisableDate={disableWeekends}
                  value={acceptanceDate}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Fragment>
          : <div className="col-12"><p>There are currently no start dates in the system.</p></div>
        }

        
      </div>
      <hr />
    </Fragment>
  );
}

export default ResultsStartDate;
